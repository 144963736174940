@import "constants";

.plans-table {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 60px;

  .plan {
    width: 25%;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-size: 18px;
    font-weight: bold;
    border-right: 0;

    &.last {
      border-right: 1px solid $primary-color;
    }

    .plan-row {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;
      height: 80px;

      &.column {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }

      &.no-body {
        height: calc(350px + 80px);
      }

      &.body {
        height: 350px;
      }

      &.border-bottom {
        border-bottom: 1px solid $primary-color;
      }
    }

    li {
      display: flex;
      gap: 10px;
      font-size: 14px;
      padding-bottom: 10px;
      font-weight: normal;
      list-style-type: none;
    }
  }
}

@media screen and (max-width: 1080px) {
  .plans-table {
    justify-content: center;
    align-items: center;
    padding-top: 0;
    .plan {
      width: 46%;
      margin: 10px;
      border: 1px solid $primary-color;
    }
  }
}

@media screen and (max-width: 700px) {
  .plans-table {
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    .plan {
      width: 90%;
      margin-top: 30px;
      border: 1px solid $primary-color;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
