@import "constants";

// Page
.page {
  //min-height: 100vh;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //&.prices-page {
  //  min-height: 100vh;
  //}
}

.article {
  padding: 0 40px;
}

.center-content {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
}

// Main Button
.button {
  padding: 10px;
  background-color: $primary-color;
  color: white;
  border-radius: 2px;
  user-select: none;
  cursor: pointer;
}

// Fade
.fade {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: .2s ease-in-out;
}

// Main Title
.main-title {
  position: absolute;
  z-index: 2;
  text-align: center;
  color: white;
  user-select: none;
  margin-bottom: 120px;

  h1 {
    font-size: 72px;
  }

  .main-logo {
    width: 500px;
  }

  h2 {
    margin-top: 20px;
    font-size: 36px;
  }
}

// Texts
.text-header {
  font-size: 30px;
  color: $primary-color;
  padding: 20px 0 40px 0;
  opacity: 0;
  transition: .4s;
  transform: translateX(-500px);

  &.visible {
    transform: translateX(0);
    opacity: 1;
  }

  &.clickable {
    cursor: pointer;
  }

}

.text-subheader {
  font-size: 30px;
  color: $primary-color;
  padding: 80px 0 0 0;
  opacity: 0;
  transition: .4s;
  transform: translateX(-500px);

  &.visible {
    transform: translateX(0);
    opacity: 1;
  }

  &.clickable {
    cursor: pointer;
  }

}

.text-article {
  font-size: 20px;
  padding: 20px 0 0 0;
  color: white;
  opacity: 0;
  transition: .4s;
  transform: translateX(-500px);

  &.visible {
    transform: translateX(0);
    opacity: 1;
  }

  &.clickable {
    cursor: pointer;
  }

  &.bold {
    font-weight: bold;
  }

  &.small {
    font-size: 14px;
  }
}

.text-highlight {
  color: $primary-color;
  font-weight: bold;
}

// Font Awesome
i.fa {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}


@media screen and (max-width: 560px) {
  .main-title {
    h1 {
      font-size: 56px;
    }

    .main-logo {
      width: 250px;
    }

    h2 {
      margin-top: 20px;
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 450px) {
  .main-title {
    h1 {
      font-size: 40px;
    }

    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .article {
    padding: 0 20px;
  }

}

@media (min-width: 1200px) {
  .text-header {
    font-size: 40px;
  }
  .text-subheader {
    font-size: 30px;
  }
  .text-article {
    font-size: 20px;
    &.small {
      font-size: 20px;
    }
  }
}

@media (min-width: 1800px) {
  .text-header {
    font-size: 45px;
  }
  .text-subheader {
    font-size: 35px;
  }
  .text-article {
    font-size: 25px;
  }
}
