.counters-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  padding: 40px;
  background: url("../assets/counters_back.jpg");
  background-size: 150%;
  width: 100%;

  .counters {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;

    .counter-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .count-number {
        font-size: 80px;
        color: white;
      }

      .count-text {
        color: white;
        font-size: 20px;
      }
    }
  }
  .counters-button {
    padding: 70px 0 30px 0;
  }
}

@media screen and (max-width: 450px) {
  .counters-section {
    padding: 40px 10px;

    .counters {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;

      .counter-container {
        .count-number {
          font-size: 60px;
        }
      }
    }
  }

}
