* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #2f2f2f;
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  //min-height: 200vh;
  background: url("../assets/paper_dark.jpg");
}

a {
  text-decoration: none; /* Remove underline by default */
  color: inherit; /* Inherit text color from parent */
}

a:hover {
  text-decoration: none; /* Add underline on hover */
}

input, textarea, button {
  margin: 0;
  padding: 8px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background: none;
  appearance: none; /* Remove default styles like arrow in select */
  resize: none;
}

i {
  font-family: 'FontAwesome', sans-serif;
  font-style: normal;
}
