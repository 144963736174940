@import "constants";

.contacts {
  padding: 100px 30px;
  color: white;

  .personal-container {
    display: flex;
    //justify-content: space-around;
    gap: 50px;
    padding: 30px 0 20px 0;
    .man {
      width: 30%;
    }
  }

  .contacts-container {
    display: flex;
    flex-direction: row;

    .contacts-article {
      width: 50%;
      .contacts-list {
        margin-top: 60px;
        display: flex;
        gap: 40px;

        .socials-list {
          display: flex;
          gap: 20px;
        }

        i {
          width: 16px;
          height: 16px;
        }
      }
    }

    #form {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 0 30px;

      .form-group {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        label {
          font-size: 14px;
          padding: 10px 0 5px 0;
        }

        .input-container {
          display: flex;
          flex-direction: column;
          width: 100%;

          input {
            background-color: #2a2a2a;
            color: $primary-color;
            border-radius: 2px;
          }

        }

        .message-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding-bottom: 20px;
          textarea {
            background-color: #2a2a2a;
            color: $primary-color;
            border-radius: 2px;
            height: 200px
          }
        }
      }
      .button {
        align-self: flex-end;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .contacts {
    .contacts-container {
      flex-direction: column;
      .contacts-article {
        width: 100%;
      }
      #form {
        width: 100%;
        padding: 40px 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .contacts {
    .personal-container {
      flex-direction: column;
      gap: 20px;
      padding: 30px 0 20px 0;
      .man {
        width: 100%;
      }
    }
    .contacts-container {
      .contacts-article {
        .contacts-list {
          flex-direction: column;
          align-items: flex-start;
          .link {
            padding: 5px 0;
          }
        }
      }
      #form {
        .form-group {
          flex-direction: column;
          gap: 0;
        }
      }
    }
  }
}
