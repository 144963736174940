.header {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  padding: 0 60px;
  width: 100%;
  transition: .2s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  z-index: 6;
  user-select: none;

  &.collapsed {
    height: 80px;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .header-logo {
      display: flex;
      align-items: center;
      transition: .2s ease-in-out;
    }

    .navigation-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .header-links {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }

    }

  }

}

@media screen and (max-width: 700px) {
  .header {
    .header-content {
      .navigation-bar {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #2a2a2a;
        padding: 40px 20px;
        width: 250px;
        height: 100vh;
        top: 0;
        right: 0;
        transition: .2s ease-in-out;
        transform: translateX(250px);

        &.opened {
          transform: translateX(0);
        }

        .toggleBar {
          position: absolute;
          width: 40px;
          height: 30px;
          left: -80px;
          top: 35px;
          transition: .2s ease-in-out;

          .toggleBarStick {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: white;
            transition: .2s ease-in-out;

            &:nth-of-type(1) {
              top: 0;
            }

            &:nth-of-type(2) {
              top: 14px;
            }

            &:nth-of-type(3) {
              bottom: 0;
            }
          }

          &.opened {
            .toggleBarStick {
              &:nth-of-type(1) {
                top: 13px;
                transform: rotateZ(45deg);
              }

              &:nth-of-type(2) {
                opacity: 0;
              }

              &:nth-of-type(3) {
                bottom: 15px;
                transform: rotateZ(-45deg);
              }
            }
          }

          &.collapsed {
            top: 26px;
          }
        }

        .header-links {
          flex-direction: column;
          align-items: flex-start;
        }
      }

    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    .header-content {
      .header-logo {
        &.opened {
          opacity: 0;
        }
      }
    }
  }
}

