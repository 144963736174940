@import "constants";

.services { // IntersectionObserver hidden element
  height: 100%;
  width: 100%;
  position: absolute;
}

.service-card-expand-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .services-card {
    text-align: center;
    cursor: pointer;
  }

    .service-item-details {
      max-height: 0;
      overflow: hidden;
      transition: .1s ease-in-out;
      width: 100%;

      &.visible {
        max-height: 1000vh;
        transition: 2s ease-in-out;
      }
    }

    &:hover {
      .services-card-image {
        transform: scale(1.1);
      }
    }

  .details-button {
    background-color: $primary-color;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    z-index: 1;
    align-self: center;
    transition: .2s ease-in-out;
  }

    &.active {
      .services-card {
        .services-card-title {
          color: white;
        }

        .services-card-image {
          filter: none;
        }
      }
      .details-button {
        opacity: 0;
      }
    }

}

.services-card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border: 2px solid $primary-color;
  //height: calc(33vw - 60px);
  //width: calc(33vw - 20px);
  width: 80%;
  //max-height: 300px;
  border-radius: 4px;
  background-color: $primary-fade;
  overflow: hidden;
  user-select: none;
  opacity: 0;
  transition: .4s;
  transform: translateX(-500px);

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }

  &.full-screen {
    width: 100%;
    max-width: 800px;
  }

  .services-card-title {
    position: relative;
    font-size: 30px;
    color: $primary-color;
    z-index: 2;
  }

  .services-card-description {
    position: relative;
    padding-top: 20px;
    font-size: 18px;
    color: white;
    z-index: 2;
  }

  .blur-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(2px);
  }

  .services-card-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: grayscale(1);
    transition: .1s ease-in-out;
  }
}

@media screen and (max-width: 1060px) {
  .services-card {
    //height: calc(50vw - 60px);
    //width: calc(50vw - 20px);
  }
}

@media screen and (max-width: 700px) {
  .services-card {
    //height: 300px;
    //width: 400px;
  }
}

@media screen and (max-width: 450px) {
  .services-card {
    //height: 200px;
    //width: 300px;

    .services-card-title {
      font-size: 16px;
    }

    .services-card-description {
      font-size: 14px;
    }
  }
}
