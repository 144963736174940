@import "constants";

.slider {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  padding-bottom: 120px;

  .slider-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100vh;
    overflow: hidden;
    top: 0;

    .slider-images {
      display: flex;
      width: calc(100vw * 5);
      height: 100%;

      .slider-image-wrapper {
        position: relative;

        img {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
          overflow: hidden;
          filter: grayscale(1);
          //transition: transform 0.3s ease;
        }

        .image-info {
          position: absolute;
          width: 100%;
          font-size: 22px;
          text-align: center;
          //bottom: 250px;
          bottom: 30%;
          padding: 10px 30px;
          color: white;
          font-weight: bold;
          transition: .4s;

          &.hidden {
            opacity: 0;
          }

        }

      }

    }

  }

  .slider-controls-bar {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 10px;
    z-index: 4;
    bottom: 120px;
    left: 0;
    right: 0;

    .slider-control-button {
      width: 30px;
      height: 14px;
      background-color: $gray-color;
      cursor: pointer;

      &.active {
        border: 2px solid $primary-color;
        background-color: $primary-color;
      }
    }

  }

}

@media screen and (max-width: 450px) {
  .slider {
    .slider-container {
      .slider-images {
        .slider-image-wrapper {
          .image-info {
            bottom: 200px;
            transition: .4s;
            font-size: 16px;
          }
        }
      }
    }

    .slider-controls-bar {
      bottom: 140px;
    }
  }
}
