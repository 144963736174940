@import "constants";

.why { // IntersectionObserver hidden element
  height: 100%;
  width: 100%;
  position: absolute;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  gap: 10px;

  &.column {
    margin-top: 40px; // Services Screen
  }

  &.items-left {
    text-align: left;
    gap: 20px;
  }

  .section-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border: 2px solid $primary-color;
    height: 300px;
    width: 400px;
    border-radius: 4px;
    background-color: $primary-fade;
    opacity: 0;
    transition: .4s;
    transform: translateX(-500px);

    &.visible {
      opacity: 1;
      transform: translateX(0);
    }

    .section-card-title {
      font-size: 24px;
      color: $primary-color;
      padding: 20px 0 0 0;
    }

    .section-card-description {
      padding-top: 20px;
      font-size: 18px;
      color: white
    }

    .section-card-icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;

      i {
        font-size: 60px;
        padding: 20px 0;
        color: $primary-color;
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .section-container {
    .section-card {
      height: calc(50vw - 60px);
      width: calc(50vw - 20px);
    }
  }
}

@media screen and (max-width: 800px) {
  .section-container {
    .section-card {
      .section-card-icon-container {
        padding-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .section-container {
    .section-card {
      height: 300px;
      width: 400px;
    }
  }
}

@media screen and (max-width: 450px) {
  .section-container {
    .section-card {
      height: 200px;
      width: 300px;

      .section-card-title {
        padding-top: 5px;
        font-size: 16px;
      }

      .section-card-description {
        padding-top: 5px;
        font-size: 14px;
      }

      .section-card-icon-container {
        i {
          padding: 4px 0;
          font-size: 20px;
        }
      }
    }
  }
}
